import Script from "next/script";
import { useEffect } from "react";
import type { PageProps } from "../../pages/[[...path]]";

declare global {
	interface Window {
		dataLayer: unknown[];
	}
}

export type Page = Record<string, string>;

export const pushPageView = (page: unknown) => {
	window.dataLayer.push({
		event: "page_view",
		page: page,
		_clear: true,
	});
};

export function gtagPush(..._args: Array<unknown>) {
	// eslint-disable-next-line prefer-rest-params
	window.dataLayer.push(arguments);
}

type GoogleTagManagerProps = {
	gtmId: string;
	page: PageProps["page"];
};

export const GoogleTagManger = (props: GoogleTagManagerProps) => {
	const { gtmId, page } = props;

	useEffect(() => {
		if (page.genericPage) {
			pushPageView({
				name: page.genericPage.title,
			});
		} else if (page.areaPage) {
			pushPageView({
				name: page.areaPage.name,

				area: {
					id: page.areaPage.id,
					name: page.areaPage.name,
				},
			});
		} else if (page.organizationPage) {
			pushPageView({
				name: page.organizationPage.name,
				organization: {
					id: page.organizationPage.id,
					name: page.organizationPage.name,
				},
			});
		} else if (page.postPage) {
			pushPageView({
				name: page.postPage.title,
				post: {
					id: page.postPage.id,
					title: page.postPage.title,
				},
			});
		}
	}, [page]);

	return (
		<>
			<Script
				id="google-tag-manager-setup"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

function getCookie() {
   const value = "; " + document.cookie;
   const parts = value.split("; " + "CookieConsent" + "=");
    if (parts.length === 2) {
        const response = parts.pop().split(";").shift();
        return response;
    }
}

function getConsentPreferences() {
   const consentCookie = getCookie();
   return {
       'ad_storage': consentCookie && consentCookie.indexOf('marketing:true') > -1 ? 'granted' : 'denied',
       'ad_user_data': consentCookie && consentCookie.indexOf('marketing:true') > -1 ? 'granted' : 'denied',
       'ad_personalization': consentCookie && consentCookie.indexOf('marketing:true') > -1 ? 'granted' : 'denied',
       'analytics_storage': consentCookie && consentCookie.indexOf('statistics:true') > -1 ? 'granted' : 'denied',
       'personalization_storage': consentCookie && consentCookie.indexOf('preferences:true') > -1 ? 'granted' : 'denied',
       'functionality_storage': consentCookie && consentCookie.indexOf('preferences:true') > -1 ? 'granted' : 'denied',
       'security_storage': consentCookie && consentCookie.indexOf('necessary:true') > -1 ? 'granted' : 'denied'
   };
}

var consentPreferences = getConsentPreferences();
gtag('consent', 'default', consentPreferences);
window.dataLayer.push({
   'event': 'consent_init',
   'consent': consentPreferences,
   '_clear': true
});


var checkConsent = function(arr, callback) {
   arr.push = function(e) {
       Array.prototype.push.call(arr, e);
       callback(arr);
   };
};

let oldCookie = getCookie();
let oldHash = oldCookie ? oldCookie.match(/.*?stamp\:%27(.*?)%27\%2C.*/)[1] : undefined;

checkConsent(window.dataLayer, function(newDl) {
   let lastPush = newDl[newDl.length - 1];
   if (lastPush.event === 'cookie_consent_update') {
       let newCookie = getCookie();
       if (!newCookie) {
        return;
       }
       let newHash = newCookie.match(/.*?stamp\:%27(.*?)%27\%2C.*/)[1];
       if (oldHash !== newHash) {
           let consentPreferences = getConsentPreferences();
           window.dataLayer.push({
               'event': 'consent_update',
               'consent': consentPreferences,
               '_clear': true
           });
       }
   }
});`,
				}}
			/>
			<Script
				strategy="afterInteractive"
				id="google-tag-manager-script"
				dangerouslySetInnerHTML={{
					__html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`,
				}}
			/>
			<Script
				strategy="afterInteractive"
				id="Cookiebot"
				src="https://consent.cookiebot.com/uc.js"
				type="text/javascript"
				data-cbid="9de6d2b8-c8bb-415e-a15d-2884ba240319"
				data-blockingmode="auto"
				async
			/>
			<noscript>
				<iframe
					title="Google Tag Manager"
					src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
					height="0"
					width="0"
					style={{
						display: "none",
						visibility: "hidden",
					}}
				/>
			</noscript>
		</>
	);
};
