import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent, MouseEvent, ReactNode } from 'react'
import { PostAreaItemResult } from '../data/PostAreaItemFragment'
import styles from './AreaItem.module.sass'
import { ResponsiveImage } from './ResponsiveImage'

export type AreaItemProps = {
	localesByLocale: PostAreaItemResult['localesByLocale']
	isTransparent?: boolean
	isActive?: boolean
}

export const AreaItem: FunctionComponent<AreaItemProps> = ({
	localesByLocale,
	isTransparent = false,
	isActive = false,
}) => {
	const icon = localesByLocale?.root?.icon
	const name = localesByLocale?.name
	const link = localesByLocale?.link
	const color = localesByLocale?.root?.color

	return (
		<div className={clsx(styles.wrapper, isTransparent && styles.is_transparent, isActive && styles.is_active)}>
			<AreaContent link={link} isActive={isActive}>
				<div className={styles.main} style={{ '--AreaItem-color': color }}>
					<div className={styles.icon}>
						{icon && (
							<ResponsiveImage
								className={styles.image}
								src={icon.url}
								width={icon.width}
								height={icon.height}
								alt={icon.alt ?? ''}
								sizes="50px"
							/>
						)}
					</div>
					<div className={styles.name}>{name}</div>
				</div>
			</AreaContent>
		</div>
	)
}

type AreaContentProps = {
	// @TODO: is there better utility types than "Partial<NonNullable<...>>" ??
	link: Partial<NonNullable<AreaItemProps['localesByLocale']>['link']>
	children: ReactNode
	isActive: AreaItemProps['isActive']
}

const AreaContent: FunctionComponent<AreaContentProps> = ({ children, link, isActive }) => {
	const url = link?.url
	return (
		<>
			{url ? (
				<Link
					className={styles.link}
					href={url}
					onClick={(event: MouseEvent<HTMLLIElement, MouseEvent>) => {
						if (isActive) {
							event.preventDefault()
						}
					}}
				>
					{children}
				</Link>
			) : (
				<>{children}</>
			)}
		</>
	)
}
