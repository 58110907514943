import { FunctionComponent } from "react";
import { Icon } from './Icon'
import { AreaItem, AreaItemProps } from './AreaItem'
import { ContentRendererWithInlineLink } from './ContentRenderer'
import Link from 'next/link'
import Image from 'next/image'
import styles from './PostHero.module.sass'
import { ContentWithLinkResult } from '../data/ContentWithLinkFragment'
import { useTranslate } from './contexts/TranslationsContextProvider'

interface PostHeroProps {
	heroImage?: {
		url: string
		alt?: string
	}
	backLink: string
	area?: Omit<AreaItemProps, 'isTransparent'>
	title: string
	leadContent?: ContentWithLinkResult

}

export const PostHero: FunctionComponent<PostHeroProps> = ({ heroImage, backLink, area, title, leadContent }) => {
	const translate = useTranslate()

	return (
		<div className={styles.hero}>
			<div className={styles.heroBackground}>
				{heroImage && (
					<div className={styles.heroBackgroundIn}>
						{/* @TODO: on mobile use tileImage or mobile image ? */}
						<Image
							key={heroImage.url}
							className={styles.heroImage}
							src={heroImage.url}
							alt={heroImage.alt ?? ''}
							sizes="100vw"
							fill
						/>
					</div>
				)}
			</div>
			<div className={styles.heroMain}>
				<div className={styles.backLink}>
					<Link className={styles.backLinkIn} href={backLink}>
							<span className={styles.backLinkIcon}>
								<Icon name="caret" />
							</span>
						{translate('PostPage.header.backToProjects')}
					</Link>
				</div>
				<div className={styles.heroContent}>
					<div className={styles.heroContentIn}>
						{area && <AreaItem {...area} isTransparent />}
						<h1 className={styles.heroTitle}>{title}</h1>
						<div className={styles.heroLead}>
							{leadContent && <ContentRendererWithInlineLink content={leadContent} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
