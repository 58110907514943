import type { FunctionComponent } from 'react'
import styles from './PostGridTiles.module.sass'
import { PostTile, PostTileProps } from './PostTile'

export type PostGridTilesProps = {
	tiles: PostTileProps[]
}

export const PostGridTiles: FunctionComponent<PostGridTilesProps> = ({ tiles }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.tiles}>
				{tiles.map(
					(tile) =>
						tile && (
							<div key={tile.id} className={styles.tile}>
								<PostTile {...tile} />
							</div>
						),
				)}
			</div>
		</div>
	)
}
