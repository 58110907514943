import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo } from 'react'
import { PageProps } from '../pages/[[...path]]'
import { AppBanner, AppBannerLinks } from './AppBanner'
import { AreaItem } from './AreaItem'
import { CarouselPosts } from './CarouselPosts'
import { Container } from './Container'
import { ContentRenderer, ContentRendererWithInlineLink } from './ContentRenderer'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { Icon } from './Icon'
import styles from './PostPage.module.sass'
import { PostHero } from './PostHero'

export type PostPageProps = NonNullable<PageProps['page']['postPage']>

export const PostPage: FunctionComponent<PostPageProps> = ({
	id,
	area,
	title,
	leadContent,
	content,
	heroImage,
	tileImage,
	tileVideo,
}) => {
	const { query } = useRouter()
	// backPath is used to go back to the previous page (the parameter is passed into link in PostTle.tsx)
	const backLink = useMemo(
		() => (typeof query.backPath === 'string' ? query.backPath : area.localesByLocale?.link?.url ?? '/'),
		[area.localesByLocale?.link?.url, query.backPath],
	)
	const banner = area.localesByLocale?.banner
	const color = area.localesByLocale?.root?.color
	const otherPosts = area.localesByLocale?.root?.posts

	const filteredOtherPosts = useMemo(
		() => otherPosts?.filter((item) => item.activeRevision?.id !== id),
		[id, otherPosts],
	)

	const translation = useTranslate()

	return (
		<div
			className={clsx(styles.wrapper, otherPosts && styles.is_otherPosts)}
			style={{
				'--PostPage-hero-backgroundColor': color,
			}}
		>
			{/* @TODO: custom Hero component */}
			<PostHero
				heroImage={heroImage}
				backLink={backLink}
				area={area.localesByLocale ? { localesByLocale: area.localesByLocale } : undefined}
				title={title ?? ''}
				leadContent={leadContent}
			/>
			<div className={styles.appButtons}>
				<Container size="small">
					<div className={styles.appButtonsIn}>
						<h4 className={styles.appTitle}>{translation('PostPage.appButtons.title')}</h4>
						<AppBannerLinks />
					</div>
				</Container>
			</div>
			<div className={styles.content}>
				<div className={styles.contentSide} />
				<div className={styles.contentMain}>
					{content && (
						<ContentRenderer
							containerDisableGutters
							content={content}
							postAppBanner={{ type: 'default', ...banner, color: color, direction: 'horizontal' }}
						/>
					)}
				</div>
				<div className={styles.contentSide}>
					<div className={styles.banner}>
						{tileVideo || tileImage ? (
							<AppBanner direction="vertical" color={color} type="video" tileVideo={tileVideo} tileImage={tileImage} />
						) : banner ? (
							<AppBanner direction="vertical" color={color} type="default" {...banner} />
						) : null}
					</div>
				</div>
			</div>
			{filteredOtherPosts && filteredOtherPosts.length > 0 && (
				<div className={styles.carousel}>
					<Container>
						<h3 className={styles.carouselTitle}>{translation('PostPage.carousel.title')}</h3>
						{filteredOtherPosts && <CarouselPosts posts={filteredOtherPosts} />}
					</Container>
				</div>
			)}
		</div>
	)
}
