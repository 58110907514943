import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode, useState } from 'react'
import { InternalLinkResult } from '../data/InternalLinkFragment'
import { PostTileResult } from '../data/PostTileFragment'
import styles from './PostTile.module.sass'

export type PostTileProps = PostTileResult

export const PostTile: FunctionComponent<PostTileProps> = ({ activeRevision }) => {
	const link = activeRevision?.post?.localesByLocale?.link
	const title = activeRevision?.localesByLocale?.title
	const tileVideo = activeRevision?.tileVideo
	const tileImage = activeRevision?.tileImage

	const [videoRef, setVideoRef] = useState<null | HTMLVideoElement>(null)

	return (
		<div className={styles.wrapper}>
			<PostTileContent link={link} videoRef={videoRef}>
				<div className={styles.main}>
					{tileVideo && (
						<>
							{tileImage && (
								<Image
									className={clsx(styles.image, tileVideo && styles.is_video)}
									src={tileImage.url}
									alt={tileImage.alt ?? ''}
									fill
									sizes="100vw, (min-width: 592px) 340px, (min-width: 1200px) 250px"
								/>
							)}
							<video
								ref={setVideoRef}
								className={styles.video}
								placeholder={tileImage?.url}
								playsInline
								loop
								muted
								preload="none"
							>
								<source src={tileVideo.url} type={tileVideo.type} />
							</video>
						</>
					)}
					{!tileVideo && tileImage && (
						<Image
							className={styles.image}
							src={tileImage.url}
							alt={tileImage.alt ?? ''}
							fill
							sizes="100vw, (min-width: 592px) 340px, (min-width: 1200px) 250px"
						/>
					)}
					<div className={styles.content}>
						<h3 className={styles.title}>{title}</h3>
					</div>
				</div>
			</PostTileContent>
		</div>
	)
}

// @TODO: custom component
type PostTileContentProps = {
	link?: InternalLinkResult
	children: ReactNode
	videoRef: HTMLVideoElement | null
}

const PostTileContent: FunctionComponent<PostTileContentProps> = ({ children, link, videoRef }) => {
	const url = link?.url
	const { asPath } = useRouter()

	return (
		<>
			{url ? (
				<Link
					className={styles.link}
					href={{
						pathname: url,
						query: {
							backPath: asPath,
						},
					}}
					as={url}
					onMouseEnter={() => {
						videoRef?.play()
					}}
					onMouseLeave={() => {
						videoRef?.pause()
					}}
				>
					{children}
				</Link>
			) : (
				<>{children}</>
			)}
		</>
	)
}
