import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent, MouseEvent, ReactNode } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { InternalLinkResult } from '../data/InternalLinkFragment'
import styles from './ListItemContent.module.sass'
import { ResponsiveImage } from './ResponsiveImage'

export type ListItemContentProps = {
	icon?: ImageResult
	color?: string
	link?: InternalLinkResult
	name?: string
	isTransparent?: boolean
	isActive?: boolean
}

export const ListItemContent: FunctionComponent<ListItemContentProps> = ({
	isTransparent = false,
	isActive = false,
	name,
	link,
	icon,
	color,
}) => {
	return (
		<div className={clsx(styles.wrapper, isTransparent && styles.is_transparent, isActive && styles.is_active)}>
			<ListItemContentIn link={link} isActive={isActive}>
				<div className={styles.main} style={{ '--ListItemContent-color': color }}>
					{icon && (
						<div className={styles.icon}>
							<ResponsiveImage
								className={styles.image}
								src={icon.url}
								width={icon.width}
								height={icon.height}
								alt={icon.alt ?? ''}
								sizes="50px"
							/>
						</div>
					)}
					<div className={styles.name}>{name}</div>
				</div>
			</ListItemContentIn>
		</div>
	)
}

type ListItemContentInProps = {
	link?: InternalLinkResult
	children: ReactNode
	isActive: ListItemContentProps['isActive']
}

const ListItemContentIn: FunctionComponent<ListItemContentInProps> = ({ children, link, isActive }) => {
	const url = link?.url
	return (
		<>
			{url ? (
				<Link
					className={styles.link}
					href={url}
					onClick={(event: MouseEvent<HTMLLIElement, MouseEvent>) => {
						if (isActive) {
							event.preventDefault()
						}
					}}
				>
					{children}
				</Link>
			) : (
				<>{children}</>
			)}
		</>
	)
}
