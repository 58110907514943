import clsx from 'clsx'
import { FunctionComponent, useMemo, useState } from 'react'
import { PostAreaItemResult } from '../data/PostAreaItemFragment'
import styles from './AreaList.module.sass'
import { ListItem } from './ListItem'
import { ListItemContent } from './ListItemContent'

// @TODO: unite with OrganizationList.tsx
export type AreaListProps = {
	areas: PostAreaItemResult[]
	activeAreaId?: string
}

export const AreaList: FunctionComponent<AreaListProps> = ({ areas, activeAreaId }) => {
	const filteredAreasWithPosts = areas.filter(
		(area) => (area.localesByLocale?.root?.paginatePosts.pageInfo.totalCount ?? 0) > 0,
	)

	return (
		<nav className={styles.wrapper}>
			<FilteredAreaList areas={filteredAreasWithPosts} activeAreaId={activeAreaId} />
			<FilteredAreaList areas={filteredAreasWithPosts} activeAreaId={activeAreaId} deviceType="desktop" />
		</nav>
	)
}

type FilteredAreaListProps = AreaListProps & {
	deviceType?: 'mobile' | 'desktop'
}

const FilteredAreaList: FunctionComponent<FilteredAreaListProps> = ({ areas, activeAreaId, deviceType = 'mobile' }) => {
	if (deviceType === 'mobile') {
		return <FilteredAreaListMobile areas={areas} activeAreaId={activeAreaId} />
	} else if (deviceType === 'desktop') {
		return (
			<div className={clsx(styles.list, styles.is_desktop)}>
				{areas.map((area, count) => (
					<ListItem key={area.id} type="desktop" isActive={activeAreaId === area.localesByLocale?.id} count={count}>
						<ListItemContent
							name={area.localesByLocale?.name}
							icon={area.localesByLocale?.root?.icon}
							color={area.localesByLocale?.root?.color}
							link={area.localesByLocale?.link}
							isActive={activeAreaId === area.localesByLocale?.id}
						/>
					</ListItem>
				))}
			</div>
		)
	}

	return <></>
}

type FilteredAreaListMobileProps = Omit<FilteredAreaListProps, 'deviceType'>

const FilteredAreaListMobile: FunctionComponent<FilteredAreaListMobileProps> = ({ areas, activeAreaId }) => {
	const [openSelect, setOpenSelect] = useState(false)

	const activeItem = useMemo(
		() => areas.filter((item) => activeAreaId === item.localesByLocale?.id),
		[activeAreaId, areas],
	)

	const nonActiveItems = useMemo(
		() => areas.filter((item) => activeAreaId !== item.localesByLocale?.id),
		[activeAreaId, areas],
	)
	return (
		<div className={styles.main}>
			<div className={styles.isHidden}>
				{activeItem[0] && (
					<ListItem
						type="mobile"
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
						isActive={activeAreaId === activeItem[0].localesByLocale?.id}
					>
						<ListItemContent
							name={activeItem[0].localesByLocale?.name}
							icon={activeItem[0].localesByLocale?.root?.icon}
							color={activeItem[0].localesByLocale?.root?.color}
							link={activeItem[0].localesByLocale?.link}
							isActive={activeAreaId === activeItem[0].localesByLocale?.id}
						/>
					</ListItem>
				)}
			</div>
			<div className={clsx(styles.list, openSelect && styles.is_selectOpen, styles[`is_mobile`])}>
				{activeItem[0] && (
					<ListItem
						type="mobile"
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
						isActive={activeAreaId === activeItem[0].localesByLocale?.id}
					>
						<ListItemContent
							name={activeItem[0].localesByLocale?.name}
							icon={activeItem[0].localesByLocale?.root?.icon}
							color={activeItem[0].localesByLocale?.root?.color}
							link={activeItem[0].localesByLocale?.link}
							isActive={activeAreaId === activeItem[0].localesByLocale?.id}
						/>
					</ListItem>
				)}
				{nonActiveItems.map((area, count) => (
					<ListItem key={area.id} type="mobile" count={count} setOpenSelect={setOpenSelect} openSelect={openSelect}>
						<ListItemContent
							name={area.localesByLocale?.name}
							icon={area.localesByLocale?.root?.icon}
							color={area.localesByLocale?.root?.color}
							link={area.localesByLocale?.link}
							isActive={activeAreaId === area.localesByLocale?.id}
						/>
					</ListItem>
				))}
			</div>
		</div>
	)
}
