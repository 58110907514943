import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent, MouseEvent } from 'react'
import { LinkItemResult } from '../data/LinkItemFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './GenericPageLinkItem.module.sass'

export type GenericPageLinkItemProps = LinkItemResult & {
	isActive?: boolean
}

export const GenericPageLinkItem: FunctionComponent<GenericPageLinkItemProps> = ({ link, isActive = false }) => {
	return (
		<div className={clsx(styles.wrapper, isActive && styles.is_active)}>
			{link && (
				<Link
					className={styles.link}
					href={contemberLinkToHref(link)}
					onClick={(event: MouseEvent<HTMLLIElement, MouseEvent>) => {
						if (isActive) {
							event.preventDefault()
						}
					}}
					// @TODO: own component
					target={link.isTargetBlank ? '_blank' : undefined}
				>
					{link.title}
				</Link>
			)}
		</div>
	)
}
