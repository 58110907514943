import clsx from 'clsx'
import { FunctionComponent, useMemo } from 'react'
import { LinkResult } from '../data/LinkFragment'
import { GenericPageType } from '../generated/content'
import { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { isDefined } from '../utilities/isDefined'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'
import { GenericPageLinkList, GenericPageLinkListProps } from './GenericPageLinkList'

export type GenericPageProps = NonNullable<PageProps['page']['genericPage']>

export const GenericPage: FunctionComponent<GenericPageProps> = ({ root, link, content, pageLinks }) => {
	const type = root?.type
	const url = link?.url

	const concatenatedLinks = useMemo(
		() =>
			pageLinks?.localesByLocale?.linkGroups?.flatMap((linkGroup) => linkGroup.linkList?.items).filter(isDefined) ?? [],
		[pageLinks],
	)
	return (
		<div className={styles.wrapper}>
			{type === GenericPageType.about ? (
				<Container size="wide">
					<div className={styles.is_typeAbout}>
						<aside className={styles.aside}>
							{pageLinks?.localesByLocale?.linkGroups && url && (
								<GenericPageLinkGroupList
									items={pageLinks.localesByLocale.linkGroups}
									currentUrl={url}
									concatenatedLinks={concatenatedLinks}
								/>
							)}
						</aside>
						<div className={styles.content}>
							{content && <ContentRenderer containerSize="small" containerDisableGutters content={content} />}
						</div>
					</div>
				</Container>
			) : (
				<div className={styles.content}>{content && <ContentRenderer content={content} />}</div>
			)}
		</div>
	)
}

type LinkItem = {
	id: string
	link?: LinkResult
}

type GenericPageLinkGroupListProps = {
	items: NonNullable<NonNullable<GenericPageProps['pageLinks']>['localesByLocale']>['linkGroups']
	concatenatedLinks: LinkItem[]
	currentUrl: NonNullable<GenericPageProps['link']>['url']
}

const GenericPageLinkGroupList: FunctionComponent<GenericPageLinkGroupListProps> = ({
	items,
	concatenatedLinks,
	currentUrl,
}) => {
	const activeItemId = useMemo(() => {
		return concatenatedLinks?.find((item) => item.link && contemberLinkToHref(item.link) === currentUrl)?.id
	}, [concatenatedLinks, currentUrl])
	return (
		<>
			{items.map((linkGroup) => (
				<div key={linkGroup.id} className={clsx(styles.group, styles.is_desktop)}>
					{linkGroup.linkList && <GenericPageLinkGroup group={linkGroup} activeItemId={activeItemId} />}
				</div>
			))}
			<div className={clsx(styles.group, styles.is_mobile)}>
				<GenericPageLinkList deviceType="mobile" concatenatedLinks={concatenatedLinks} activeItemId={activeItemId} />
			</div>
		</>
	)
}

type GenericPageLinkGroupProps = Pick<GenericPageLinkListProps, 'activeItemId'> & {
	group?: NonNullable<NonNullable<GenericPageProps['pageLinks']>['localesByLocale']>['linkGroups'][number]
}

const GenericPageLinkGroup: FunctionComponent<GenericPageLinkGroupProps> = ({ group, activeItemId }) => {
	if (group?.linkList) {
		return <GenericPageLinkList deviceType="desktop" items={group.linkList.items} activeItemId={activeItemId} />
	}

	return <></>
}
