import clsx from 'clsx'
import { FunctionComponent, useMemo } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { PageProps } from '../pages/[[...path]]'
import { isDefined } from '../utilities/isDefined'
import { AreaList } from './AreaList'
import { Container } from './Container'
import { OrganizationList } from './OrganizationList'
import { PostGridTiles } from './PostGridTiles'
import { ResponsiveImage } from './ResponsiveImage'
import styles from './SideBarWithPostsPage.module.sass'

export type SideBarWithPostsPageProps = areaPageProps | organizationPageProps

type areaPageProps = {
	type: 'area'
	page: NonNullable<PageProps['page']['areaPage']>
}

type organizationPageProps = {
	type: 'organization'
	page: NonNullable<PageProps['page']['organizationPage']>
}

export const SideBarWithPostsPage: FunctionComponent<SideBarWithPostsPageProps> = ({ type, page }) => {
	const posts = useMemo(() => {
		switch (type) {
			case 'area':
				const PinnedAreaPosts =
					(page.root?.posts ? page.root?.posts?.sort((post) => (post.isPinnedInArea ? -1 : 1)) : []) ?? []
				return PinnedAreaPosts.filter(isDefined)
			case 'organization':
				const PinnedOrganizationPosts =
					(page.root?.posts ? page.root?.posts?.sort((post) => (post.isPinnedInOrganization ? -1 : 1)) : []) ?? []
				return PinnedOrganizationPosts.filter(isDefined)
		}
	}, [page.root, type])

	return (
		<div className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.main}>
					<SideBarWithPostsPageHeader
						type="mobile"
						icon={page.root?.icon}
						title={page.name}
						description={page.description}
					/>
					<aside className={styles.side}>
						{type === 'area' ? (
							<AreaList areas={page.areas} activeAreaId={page.id} />
						) : (
							<OrganizationList organizations={page.organizations} activeOrganizationId={page.id} />
						)}
					</aside>
					<div className={styles.posts}>
						{page.name && (
							<div className={styles.is_desktop}>
								<SideBarWithPostsPageHeader
									type="desktop"
									icon={page.root?.icon}
									title={page.name}
									description={page.description}
								/>
							</div>
						)}
						{posts && posts.length > 0 && <PostGridTiles tiles={posts} />}
					</div>
				</div>
			</Container>
		</div>
	)
}

export type SideBarWithPostsPageHeaderProps = {
	type: 'mobile' | 'desktop'
	icon?: ImageResult
	title?: string
	description?: string
}

export const SideBarWithPostsPageHeader: FunctionComponent<SideBarWithPostsPageHeaderProps> = ({
	type,
	icon,
	title,
	description,
}) => (
	<div className={clsx(styles.header, styles[`is_${type}`])}>
		<div className={styles.headerWrapperTitle}>
			{icon && (
				<div className={styles.headerIcon}>
					<ResponsiveImage src={icon.url} width={icon.width} height={icon.height} alt={icon.alt ?? ''} sizes="50px" />
				</div>
			)}
			<h2 className={styles.headerTitle}>{title}</h2>
		</div>
		{description && <p className={styles.headerDescription}>{description}</p>}
	</div>
)
