import clsx from 'clsx'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { PostTileResult } from '../data/PostTileFragment'
import styles from './CarouselPosts.module.sass'
import { Icon } from './Icon'
import { PostTile } from './PostTile'

export type CarouselPostsProps = {
	posts: PostTileResult[]
}

export const CarouselPosts: FunctionComponent<CarouselPostsProps> = ({ posts }) => {
	const cardsElement = useRef<HTMLDivElement>(null)
	const cardElement = useRef<HTMLDivElement>(null)

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideModifier = 1.1 // purpose: slide to the sides if it's almost at the beginning/end

		const scrollSlideOffset = direction * cardWidth * scrollSlideModifier

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [isInactivePreviousButton, setInactivePreviousButton] = useState(false)
	const [isInactiveNextButton, setInactiveNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}

		setInactivePreviousButton(false)
		setInactiveNextButton(false)
		if (cardsElement.current.scrollLeft === 0) {
			setInactivePreviousButton(true)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setInactiveNextButton(true)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])

	return (
		<div className={styles.wrapper}>
			<button
				type="button"
				className={clsx(styles.button, styles.view_previous)}
				onClick={() => scroll(-1)}
				aria-label="previous"
				disabled={isInactivePreviousButton}
			>
				<Icon name="caret" />
			</button>
			<div className={styles.tiles} ref={cardsElement} onScroll={() => onSlideChange()}>
				{posts.map((item) => (
					<div key={item.id} className={styles.tile} ref={cardElement}>
						<div className={styles.tileIn}>
							<PostTile {...item} />
						</div>
					</div>
				))}
			</div>
			<button
				type="button"
				className={clsx(styles.button, styles.view_next)}
				onClick={() => scroll(1)}
				aria-label="next"
				disabled={isInactiveNextButton}
			>
				<Icon name="caret" />
			</button>
		</div>
	)
}
