import clsx from 'clsx'
import { FunctionComponent, useMemo, useState } from 'react'
import { PostOrganizationItemResult } from '../data/PostOrganizationItemFragment'
import { ListItem } from './ListItem'
import { ListItemContent } from './ListItemContent'
import styles from './OrganizationList.module.sass'

// @TODO: unite with AreaList.tsx
export type OrganizationListProps = {
	organizations: PostOrganizationItemResult[]
	activeOrganizationId?: string
}

export const OrganizationList: FunctionComponent<OrganizationListProps> = ({ organizations, activeOrganizationId }) => {
	return (
		<nav className={styles.wrapper}>
			<FilteredOrganizationList organizations={organizations} activeOrganizationId={activeOrganizationId} />
			<FilteredOrganizationList
				organizations={organizations}
				activeOrganizationId={activeOrganizationId}
				deviceType="desktop"
			/>
		</nav>
	)
}

type FilteredOrganizationListProps = OrganizationListProps & {
	deviceType?: 'mobile' | 'desktop'
}

const FilteredOrganizationList: FunctionComponent<FilteredOrganizationListProps> = ({
	organizations,
	activeOrganizationId,
	deviceType = 'mobile',
}) => {
	if (deviceType === 'mobile') {
		return <FilteredOrganizationListMobile organizations={organizations} activeOrganizationId={activeOrganizationId} />
	} else if (deviceType === 'desktop') {
		return (
			<div className={clsx(styles.list, styles.is_desktop)}>
				{organizations.map((organization, count) => (
					<ListItem
						key={organization.id}
						type="desktop"
						isActive={activeOrganizationId === organization.localesByLocale?.id}
						count={count}
					>
						<ListItemContent
							name={organization.localesByLocale?.name}
							icon={organization.localesByLocale?.root?.icon}
							link={organization.localesByLocale?.link}
							isActive={activeOrganizationId === organization.localesByLocale?.id}
						/>
					</ListItem>
				))}
			</div>
		)
	}

	return <></>
}

type FilteredOrganizationListMobileProps = Omit<FilteredOrganizationListProps, 'deviceType'>

const FilteredOrganizationListMobile: FunctionComponent<FilteredOrganizationListMobileProps> = ({
	organizations,
	activeOrganizationId,
}) => {
	const [openSelect, setOpenSelect] = useState(false)

	const activeItem = useMemo(
		() => organizations.filter((item) => activeOrganizationId === item.localesByLocale?.id),
		[activeOrganizationId, organizations],
	)

	const nonActiveItems = useMemo(
		() => organizations.filter((item) => activeOrganizationId !== item.localesByLocale?.id),
		[activeOrganizationId, organizations],
	)
	return (
		<div className={styles.main}>
			<div className={styles.isHidden}>
				{activeItem[0] && (
					<ListItem
						type="mobile"
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
						isActive={activeOrganizationId === activeItem[0].localesByLocale?.id}
					>
						<ListItemContent
							name={activeItem[0].localesByLocale?.name}
							icon={activeItem[0].localesByLocale?.root?.icon}
							link={activeItem[0].localesByLocale?.link}
							isActive={activeOrganizationId === activeItem[0].localesByLocale?.id}
						/>
					</ListItem>
				)}
			</div>
			<div className={clsx(styles.list, openSelect && styles.is_selectOpen, styles[`is_mobile`])}>
				{activeItem[0] && (
					<ListItem
						type="mobile"
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
						isActive={activeOrganizationId === activeItem[0].localesByLocale?.id}
					>
						<ListItemContent
							name={activeItem[0].localesByLocale?.name}
							icon={activeItem[0].localesByLocale?.root?.icon}
							link={activeItem[0].localesByLocale?.link}
							isActive={activeOrganizationId === activeItem[0].localesByLocale?.id}
						/>
					</ListItem>
				)}
				{nonActiveItems.map((organization, count) => (
					<ListItem
						key={organization.id}
						type="mobile"
						count={count}
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
					>
						<ListItemContent
							name={organization.localesByLocale?.name}
							icon={organization.localesByLocale?.root?.icon}
							link={organization.localesByLocale?.link}
							isActive={activeOrganizationId === organization.localesByLocale?.id}
						/>
					</ListItem>
				))}
			</div>
		</div>
	)
}
