import clsx from 'clsx'
import { Dispatch, FunctionComponent, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import styles from './ListItem.module.sass'

export type ListItemItemProps = {
	isActive?: boolean
	type: 'mobile' | 'desktop'
	count?: number
	setOpenSelect?: Dispatch<SetStateAction<boolean>>
	openSelect?: boolean
	children: ReactNode
}

export const ListItem: FunctionComponent<ListItemItemProps> = ({
	isActive = false,
	count,
	setOpenSelect,
	openSelect,
	type,
	children,
}) => {
	const itemRef = useRef<HTMLDivElement>(null)
	const [itemHeight, setItemHeight] = useState(65)
	useEffect(() => {
		setItemHeight(itemRef.current?.clientHeight || 65)
	}, [itemRef.current?.clientHeight])
	return (
		<div
			className={clsx(
				styles.item,
				styles[`is_${type}`],
				isActive && styles.is_active,
				openSelect && styles.is_visible, // only for mobile "select" component
			)}
			ref={itemRef}
			style={{
				'--ListItem-item-height': `${itemHeight}px`,
				'--ListItem-item-count': count || count === 0 ? count + 1 : undefined,
			}}
			onClick={() => {
				if (setOpenSelect) {
					setOpenSelect(!openSelect)
				}
			}}
		>
			{children}
			{isActive && type === 'mobile' && <div className={clsx(styles.polygon, isActive && styles.is_active)} />}
		</div>
	)
}
