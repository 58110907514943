import clsx from 'clsx'
import { FunctionComponent, useMemo, useState } from 'react'
import { GenericPageLinkItem, GenericPageLinkItemProps } from './GenericPageLinkItem'
import styles from './GenericPageLinkList.module.sass'
import { ListItem } from './ListItem'

export type GenericPageLinkListProps = (GenericPageLinkListDesktopProps | GenericPageLinkListMobileProps) & {
	activeItemId?: string
}

export type GenericPageLinkListMobileProps = {
	deviceType?: 'mobile'
	concatenatedLinks?: GenericPageLinkItemProps[]
}

export type GenericPageLinkListDesktopProps = {
	deviceType?: 'desktop'
	items: GenericPageLinkItemProps[]
}

export const GenericPageLinkList: FunctionComponent<GenericPageLinkListProps> = (props) => {
	if (props.deviceType === 'mobile' && props.concatenatedLinks) {
		return (
			<FilteredGenericPageLinkListMobile
				concatenatedLinks={props.concatenatedLinks}
				activeItemId={props.activeItemId}
			/>
		)
	} else if (props.deviceType === 'desktop') {
		return (
			<nav className={styles.wrapper}>
				<div className={clsx(styles.list, styles[`is_${props.deviceType}`])}>
					{props.items.map((item) => (
						<ListItem key={item.id} type="desktop" {...item} isActive={item.id === props.activeItemId}>
							<GenericPageLinkItem {...item} isActive={item.id === props.activeItemId} />
						</ListItem>
					))}
				</div>
			</nav>
		)
	}

	return <></>
}

type FilteredGenericPageLinkListMobileProps = Pick<GenericPageLinkListMobileProps, 'concatenatedLinks'> &
	Pick<GenericPageLinkListProps, 'activeItemId'>

const FilteredGenericPageLinkListMobile: FunctionComponent<FilteredGenericPageLinkListMobileProps> = ({
	concatenatedLinks,
	activeItemId,
}) => {
	const [openSelect, setOpenSelect] = useState(false)

	const activeItem = useMemo(
		() => concatenatedLinks && concatenatedLinks.filter((item) => activeItemId === item.id),
		[activeItemId, concatenatedLinks],
	)

	const nonActiveItems = useMemo(
		() => concatenatedLinks && concatenatedLinks.filter((item) => activeItemId !== item.id),
		[activeItemId, concatenatedLinks],
	)

	return (
		<div className={styles.main}>
			<div className={styles.isHidden}>
				{activeItem?.[0] && (
					<ListItem
						type="mobile"
						{...activeItem[0]}
						isActive={activeItem[0].id === activeItemId}
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
					>
						<GenericPageLinkItem {...activeItem[0]} isActive={activeItem[0].id === activeItemId} />
					</ListItem>
				)}
			</div>
			<div className={clsx(styles.list, openSelect && styles.is_selectOpen, styles.is_mobile)}>
				{activeItem?.[0] && (
					<ListItem
						type="mobile"
						{...activeItem[0]}
						isActive={activeItem[0].id === activeItemId}
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
					>
						<GenericPageLinkItem {...activeItem[0]} isActive={activeItem[0].id === activeItemId} />
					</ListItem>
				)}
				{nonActiveItems?.map((item, count) => (
					<ListItem
						key={item.id}
						type="mobile"
						{...item}
						isActive={item.id === activeItemId}
						count={count}
						setOpenSelect={setOpenSelect}
						openSelect={openSelect}
					>
						<GenericPageLinkItem {...item} isActive={item.id === activeItemId} />
					</ListItem>
				))}
			</div>
		</div>
	)
}
